export const PracticeCompleted = () => {
	return (
		<section className="flex flex-col justify-center items-center w-full gap-4 py-[36px]">
			<span className="text-center">
				<h2 className="pt-14 pb-4 md:pt-0 md:pb-0 text-black">Abbiamo preso in carico la tua pratica!</h2>
				<hr
				className="block md:hidden"
				style={{
					border: 'none',
					borderBottom: '4px solid white',
					color: 'black',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			/>
				<p className="pb-8 pt-6 md:pt-0 md:pb-0 text-black">
					Riceverai una mail di conferma sulla tua casella di posta elettronica
				</p>
				<p className="pb-8 text-black">
					Controlla lo stato di avanzamento della tua pratica nella tua area riservata
				</p>
				<a href="#" className=" underline font-bold text-primary">
					Area riservata
				</a>
			</span>
		</section>
	);
};
