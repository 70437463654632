import clsx from 'clsx';

interface SidebarProps {
	currentStep: number;
	setCurrentStep: (step: number) => void;
	genericInfoFormValid: boolean;
	referentFormValid: boolean;
	emergencyContactFormValid: boolean;
	productiveCycleFormValid: boolean;
	factoryBodyFormValid: boolean;
	substancesEditMode: boolean;
	setSubstancesEditMode: (editSubstancesMode: boolean) => void;

}

const steps = [
	{
		step: 1,
	},
	{
		step: 2,
	},
	{
		step: 3,
	},
];

export const Sidebar = ({
	currentStep,
	setCurrentStep,
	genericInfoFormValid,
	referentFormValid,
	emergencyContactFormValid,
	productiveCycleFormValid,
	factoryBodyFormValid,
	substancesEditMode,
	setSubstancesEditMode,

}: SidebarProps) => {
	return (
		<aside className="bg-cover sticky z-10 top-0 left-0 md:relative bg-no-repeat lg:rounded-lg sm:bg-primary ">
			<nav>
				{
					currentStep < 3 && !substancesEditMode && (
						<ol className="flex justify-center items-center pt-4 gap-4 lg:mx-autor bg-white pb-2">
							<li className="flex items-center">
								<button
									className="inline-flex rounded-full leading-none font-medium w-min h-min transition-colors duration-[400ms]" style={{ visibility: currentStep === 0 ? 'hidden' : 'visible' }}
									onClick={() => {
										setCurrentStep(currentStep - 1)
									}}
								>
									<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill='#FFC300'>
										<g id="Complete">
											<g id="F-Chevron">
												<polyline fill="none" id="Left" points="15.5 5 8.5 12 15.5 19" stroke="#FFC300" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
											</g>
										</g>
									</svg>
								</button>
							</li>
							<li className="flex items-center">
								<div
									className="py-2 inline-flex rounded-full leading-none w-auto h-min text-lg text-black font-bold"
								>
									{currentStep + 1} / {steps.length}
								</div>
							</li>
							{
								currentStep === 0 && genericInfoFormValid && referentFormValid && emergencyContactFormValid && (
									<li className="flex items-center">
										<button
											className="inline-flex leading-none font-medium w-min h-min transition-colors duration-[400ms]"
											onClick={() => setCurrentStep(currentStep + 1)}
											disabled={currentStep === steps.length}
										>
											<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill='#FFC300'>
												<g id="Complete">
													<g id="F-Chevron">
														<polyline fill="none" id="Right" points="8.5 5 15.5 12 8.5 19" stroke="#FFC300" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
													</g>
												</g>
											</svg>
										</button>
									</li>
								)
							}
							{
								currentStep === 0 && (!genericInfoFormValid || !referentFormValid || !emergencyContactFormValid) && (
									<li className="flex items-center">
										<button
											className="inline-flex rounded-full leading-none font-medium w-min h-min transition-colors duration-[400ms]" style={{ cursor: 'not-allowed' }}
											disabled
										>
											<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill='#d4d8de'>
												<g id="Complete">
													<g id="F-Chevron">
														<polyline fill="none" id="Right" points="8.5 5 15.5 12 8.5 19" stroke="#d4d8de" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
													</g>
												</g>
											</svg>
										</button>
									</li>
								)
							}
							{
								currentStep === 1 && productiveCycleFormValid && factoryBodyFormValid && (
									<li className="flex items-center">
										<button
											className="inline-flex leading-none font-medium w-min h-min transition-colors duration-[400ms]"
											onClick={() => {
												setCurrentStep(currentStep + 1)
											}}
											disabled={currentStep === steps.length}
										>
											<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill='#FFC300'>
												<g id="Complete">
													<g id="F-Chevron">
														<polyline fill="none" id="Right" points="8.5 5 15.5 12 8.5 19" stroke="#FFC300" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
													</g>
												</g>
											</svg>
										</button>
									</li>
								)
							}
							{
								currentStep === 1 && (!productiveCycleFormValid || !factoryBodyFormValid) && (
									<li className="flex items-center">
										<button
											className="inline-flex rounded-full leading-none font-medium w-min h-min transition-colors duration-[400ms]" style={{ cursor: 'not-allowed' }}
											disabled
										>
											<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill='#d4d8de'>
												<g id="Complete">
													<g id="F-Chevron">
														<polyline fill="none" id="Right" points="8.5 5 15.5 12 8.5 19" stroke="#d4d8de" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
													</g>
												</g>
											</svg>
										</button>
									</li>
								)
							}
							{
								currentStep === 2 && (
									<li className="flex items-center">
										<button
											className="inline-flex leading-none font-medium w-min h-min transition-colors duration-[400ms]" style={{ visibility: 'hidden' }}
											onClick={() => {
												// Non faccio nulla
											}}
											disabled={currentStep === steps.length}
										>
											<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill='#FFC300'>
												<g id="Complete">
													<g id="F-Chevron">
														<polyline fill="none" id="Right" points="8.5 5 15.5 12 8.5 19" stroke="#FFC300" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
													</g>
												</g>
											</svg>
										</button>
									</li>
								)
							}
						</ol>
					)
				}
				{
					currentStep < 3 && !substancesEditMode && (
						<div className='flex flex-row items-center justify-center bg-white'>
							<progress className="w-52 h-1" value={currentStep + 1} max={steps.length} />
						</div>
					)
				}
				<style>
					{`
						progress::-moz-progress-bar { background: #FFC300; }
						progress::-webkit-progress-value { background: #FFC300; }
						progress { color: #FFC300; }
					`}
				</style>
			</nav>
		</aside>
	);
};
