import { useEffect, useState } from 'react';
import { Button } from './components/button';
import { Sidebar } from './components/sidebar';
import { Signup } from './components/signup';
import classNames from 'classnames';
import ConfirmationBanner from './components/confirmationBanner';
import { FormConfiguration, GenericInfo, ProductiveCycleInfo, SubstancesInfo } from 'AppTypes';
import { ProductiveCycleForm } from './components/productiveCycle';
import { SubstancesForm } from './components/substances';
import { EditSubstance } from './components/editSubstance';
import { PracticeCompleted } from './components/practiceCompleted';

function App() {
	const [step, setStep] = useState(0);
	const [genericInfoFormValid, setGenericInfoFormValid] = useState(false);
	const [referentFormValid, setReferentFormValid] = useState(false);
	const [emergencyContactFormValid, setEmergencyContactFormValid] = useState(false);
	const [productiveCycleFormValid, setProductiveCycleFormValid] = useState(false);
	const [factoryBodyFormValid, setFactoryBodyFormValid] = useState(false);
	const [substancesEditMode, setSubstancesEditMode] = useState(false);
	const [editSubstanceId, setEditSubstanceId] = useState<number | undefined>(undefined);
	const [substanceFormValid, setSubstanceFormValid] = useState(false);

	const [formInformations, setFormInformations] =
		useState<FormConfiguration>({
			genericInfo: {
				companyName: '',
				atecoCode: '',
				companyAddress: '',
				referents: [{
					name: '',
					surname: '',
					phone: ''
				}],
				emergencyContacts: [{
					name: '',
					phone: ''
				}],
				address: '',
			},
			productiveCycleInfo: {
				productiveCycles: [{
					text: ''
				}],
				factoryBody: [{
					text: '',
					storageArea: ''
				}]
			},
			substancesInfo: {
				substances: [{
					id: 0,
					text: '',
					buildingBlockQuantity: '',
					storageQuantity: '',
					hPhrases: [],
					pPhrases: [],
					euhPhrases : [],
					ghsSignalWords : [],
					warningCodes: [],
				}]
			}
		});

	const updateGenericInfo = (genericInfo: GenericInfo) => {
		setFormInformations((formInformations) => ({
			...formInformations,
			genericInfo,
		}));
	}

	const updateProductiveCycleInfo = (productiveCycleInfo: ProductiveCycleInfo) => {
		setFormInformations((formInformations) => ({
			...formInformations,
			productiveCycleInfo,
		}));
	}

	const updateSubstancesInfo = (substancesInfo: SubstancesInfo) => {
		setFormInformations((formInformations) => ({
			...formInformations,
			substancesInfo,
		}));
	}

	const nextStep = (onGoingStep?: number) => {
		if (step === 4) return; // Da sistemare

		// if (step === 0 && practiceStarted) {
		// 	console.log('PRATICA INIZIATA, passo allo step 2');
		// 	setStep(1); // passo alla prima parte di compilazione della pratica
		// }
		// if (step === 1 || (onGoingStep && onGoingStep !== 1 && step === 1)) {
		// 	if (
		// 		!userServiceConfiguration.userInfo.name ||
		// 		!userServiceConfiguration.userInfo.surname ||
		// 		!userServiceConfiguration.userInfo.email ||
		// 		!userServiceConfiguration.userInfo.email.includes('@') ||
		// 		!userServiceConfiguration.userInfo.phone ||
		// 		!userServiceConfiguration.userInfo.city ||
		// 		!userServiceConfiguration.userInfo.address ||
		// 		!userServiceConfiguration.userInfo.password ||
		// 		!userServiceConfiguration.userInfo.confirmPassword
		// 	) {
		// 		setShowRequiredFields(true);
		// 		return;
		// 	}
		// }

		setStep((step) => {
			if (onGoingStep) return onGoingStep;
			return step + 1;
		});
	};

	const goBack = () => {
		setStep((step) => step - 1);
	};

	// Faccio in modo che ogni volta che viene cambiato lo step, la pagina venga scrollata in alto
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [step]);

	return (
		<>
			<ConfirmationBanner />
			<main className="h-fit flex flex-col bg-white text-white w-full lg:mx-auto lg:max-w-[60rem] lg:mt-20 grow">
				{
					<Sidebar
						currentStep={step}
						setCurrentStep={setStep}
						genericInfoFormValid={genericInfoFormValid}
						referentFormValid={referentFormValid}
						emergencyContactFormValid={emergencyContactFormValid}
						productiveCycleFormValid={productiveCycleFormValid}
						factoryBodyFormValid={factoryBodyFormValid}
						substancesEditMode={substancesEditMode}
						setSubstancesEditMode={setSubstancesEditMode}
					/>
				}
				<div className="bg-white px-4 relative lg:flex lg:flex-col lg:w-full">
					<form className="px-6 py-9 flex w-full grow [&_h2]:text-2xl [&_h2]:font-bold [&_h2]:text-primary-marine-blue [&_h3]:font-medium [&_h3]:text-primary-marine-blue ">
						{/* {step === 1 && ( // LA REGISTRAZIONE VERRA' GESTITA DA UNA PAGINA ESTERNA
						<Signup
							userInfo={userServiceConfiguration.userInfo}
							updateUserInfo={updateUserInfo}
							showRequired={showRequired}
						/>
					)} */}
						{step === 0 && (
							<Signup
								step={step}
								setStep={setStep}
								genericInfo={formInformations.genericInfo}
								updateGenericInfo={updateGenericInfo}
								genericInfoFormValid={genericInfoFormValid}
								setGenericInfoFormValid={setGenericInfoFormValid}
								referentFormValid={referentFormValid}
								setReferentFormValid={setReferentFormValid}
								emergencyContactFormValid={emergencyContactFormValid}
								setEmergencyContactFormValid={setEmergencyContactFormValid}
							/>
						)}
						{
							step === 1 && (
								<ProductiveCycleForm
									step={step}
									setStep={setStep}
									productiveCycleInfo={formInformations.productiveCycleInfo}
									updateProductiveCycleInfo={updateProductiveCycleInfo}
									productiveCycleFormValid={productiveCycleFormValid}
									setProductiveCycleFormValid={setProductiveCycleFormValid}
									factoryBodyFormValid={factoryBodyFormValid}
									setFactoryBodyFormValid={setFactoryBodyFormValid}
								/>
							)
						}
						{
							step === 2 && !substancesEditMode && editSubstanceId === undefined && (
								<SubstancesForm
									step={step}
									setStep={setStep}
									substancesInfo={formInformations.substancesInfo}
									updateSubstancesInfo={updateSubstancesInfo}
									substancesEditMode={substancesEditMode}
									setSubstancesEditMode={setSubstancesEditMode}
									editSubstanceId={editSubstanceId}
									setEditSubstanceId={setEditSubstanceId}
								/>
							)
						}
						{
							step === 2 && substancesEditMode && editSubstanceId !== undefined && (

								<EditSubstance
									step={step}
									setStep={setStep}
									substancesInfo={formInformations.substancesInfo}
									updateSubstancesInfo={updateSubstancesInfo}
									substancesEditMode={substancesEditMode}
									setSubstancesEditMode={setSubstancesEditMode}
									editSubstanceId={editSubstanceId}
									setEditSubstanceId={setEditSubstanceId}
									substanceFormValid={substanceFormValid}
									setSubstanceFormValid={setSubstanceFormValid}
								/>
							)
						}
						{
							step === 3 && (
								<PracticeCompleted />
							)
						}
						{ /* ... OTHER STEPS ... */}
					</form>
					{step < 3 && (
						<menu className={classNames("flex", "p-4", "mt-auto", {
							"justify-end": step === 0 || (step === 2 && substancesEditMode),
							"justify-between": step > 0 && step < 3 && !substancesEditMode,
						})}>
							{
								step > 0 && !substancesEditMode  && <Button
									onClick={() => goBack()}
									className="w-full sm:w-32 text-sm text-primary underline font-medium py-2 rounded-3xl tracking-wide"
								>
									Indietro
								</Button>
							}
							{
								step === 0 && genericInfoFormValid && referentFormValid && emergencyContactFormValid && <Button
									onClick={() => nextStep()}
									className="w-full sm:w-32 text-sm text-black bg-primary font-medium py-2 rounded-3xl tracking-wide flex flex-row gap-2 items-center justify-center"
								>
									Avanti
								</Button>
							}
							{
								step === 0 && (!genericInfoFormValid || !referentFormValid || !emergencyContactFormValid) && <Button
									onClick={() => {
										// console.log('Non puoi andare avanti')
									}}
									className="w-full sm:w-32 text-sm text-gray-700 bg-gray-400 border-0 font-medium py-2 rounded-3xl tracking-wide flex flex-row gap-2 items-center justify-center"
								>
									Avanti
								</Button>
							}
							{
								step === 1 && productiveCycleFormValid && factoryBodyFormValid && <Button
									onClick={() => nextStep()}
									className="w-full sm:w-32 text-sm text-black bg-primary font-medium py-2 rounded-3xl tracking-wide flex flex-row gap-2 items-center justify-center"
								>
									Avanti
								</Button>
							}
							{
								step === 1 && (!productiveCycleFormValid || !factoryBodyFormValid) && <Button
									onClick={() => {
										// console.log('Non puoi andare avanti')
									}}
									className="w-full sm:w-32 text-sm text-gray-700 bg-gray-400 border-0 font-medium py-2 rounded-3xl tracking-wide flex flex-row gap-2 items-center justify-center"
								>
									Avanti
								</Button>
							}
							{
								step === 2 && substancesEditMode && editSubstanceId !== undefined && substanceFormValid && <Button
									onClick={() => {
										setEditSubstanceId(undefined)
										setSubstancesEditMode(false)
									}}
									className="w-full sm:w-44 text-sm text-black bg-primary font-medium py-2 rounded-3xl tracking-wide flex flex-row gap-2 items-center justify-center"
								>
									Conferma sostanza
								</Button>
							}
							{
								step === 2 && substancesEditMode && editSubstanceId !== undefined && !substanceFormValid && <Button
									onClick={() => {
										// console.log('Non puoi andare avanti')
									}}
									className="w-full sm:w-44 text-sm text-gray-700 bg-gray-400 border-0 font-medium py-2 rounded-3xl tracking-wide flex flex-row gap-2 items-center justify-center"
								>
									Conferma sostanza
								</Button>
							}
							{
								step === 2 && !substancesEditMode && formInformations.substancesInfo.substances.length > 0 && <Button
									onClick={() => {
										console.log('Form completed!');
										console.log('formInformations final', formInformations);
										setStep(3)
									}}
									className="w-full sm:w-44 text-sm text-black bg-primary font-medium py-2 rounded-full tracking-wide flex flex-row gap-2 items-center justify-center"
								>
									Conferma e invia
								</Button>
							}
						</menu>
					)}
				</div>
			</main>
		</>
	);
}

export default App;
