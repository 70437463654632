import { FormEvent, useEffect, useState } from 'react';
import { Input } from './input';
import { EmergencyContact, GenericInfo, Referent, Substance, SubstancesInfo } from 'AppTypes';
import Multiselect from 'multiselect-react-dropdown';
import { CustomDropzone } from './customDropzone';
import { hPhrases, pPhrases, ghsSignalWords, euhPhrases, warningCodes } from './data/phrases';


interface SubstancesProps {
	step: number;
	setStep: (step: number) => void;
	substancesInfo: SubstancesInfo;
	updateSubstancesInfo: (substancesInfo: SubstancesInfo) => void;
	substancesEditMode: boolean;
	setSubstancesEditMode: (editSubstancesMode: boolean) => void;
	editSubstanceId: number;
	setEditSubstanceId: (editSubstanceId: number) => void;
	substanceFormValid: boolean;
	setSubstanceFormValid: (substanceFormValid: boolean) => void;
}

export const EditSubstance = ({
	step,
	setStep,
	substancesInfo,
	updateSubstancesInfo,
	substancesEditMode,
	setSubstancesEditMode,
	editSubstanceId,
	setEditSubstanceId,
	substanceFormValid,
	setSubstanceFormValid,
}: SubstancesProps) => {
	const [substance, setSubstance] = useState<Substance>(substancesInfo.substances[editSubstanceId]);
	const hPhrasesArray = Object.values(hPhrases);
	const pPhrasesArray = Object.values(pPhrases);
	const euhPhrasesArray = Object.values(euhPhrases);
	const ghsSignalWordsArray = Object.values(ghsSignalWords);
	const warningCodesArray = Object.values(warningCodes);


	const [hPhrasesOptions, setHPhrasesOptions] = useState<Array<{ id: number, name: string }>>(
		hPhrasesArray.map((hPhrase, index) => {
			return { id: index + 1, name: hPhrase.frase, code: hPhrase.codice };
		})
	);

	const [pPhrasesOptions, setPPhrasesOptions] = useState<Array<{ id: number, name: string }>>(
		pPhrasesArray.map((pPhrase, index) => {
			return { id: index + 1, name: pPhrase.frase, code: pPhrase.codice };
		})
	);

	const [ghsSignalWordsOptions, setGhsSignalWordsOptions] = useState<Array<{ id: number, name: string }>>(
		ghsSignalWordsArray.map((ghsSignalWord, index) => {
			return { id: index + 1, name: ghsSignalWord.frase, code: ghsSignalWord.codice };
		})
	);

	const getGHSImage = (ghsSignalWord: string) => {
		return ghsSignalWordsArray.find((ghsSignalWordArray) => ghsSignalWordArray.frase === ghsSignalWord)?.immagine;
	};

	const [euhPhrasesOptions, setEuhPhrasesOptions] = useState<Array<{ id: number, name: string }>>(
		euhPhrases.map((euhPhrase, index) => {
			return { id: index + 1, name: euhPhrase.frase, code: euhPhrase.codice };
		})
	);

	const [warningCodesOptions, setWarningCodesOptions] = useState<Array<{ id: number, name: string }>>(
		warningCodesArray.map((warningCode, index) => {
			return { id: index + 1, name: warningCode.frase + ' - ' + warningCode.avvertenza };
		})
	);


	useEffect(() => {
		const requiredSubstanceFiles = [
			'text',
			'buildingBlockQuantity',
			'storageQuantity'
		]

		const isSubstanceFormValid = requiredSubstanceFiles.every((requiredSubstanceFile) => {
			return substance[requiredSubstanceFile as keyof Substance] !== undefined && substance[requiredSubstanceFile as keyof Substance] !== '' && substance[requiredSubstanceFile as keyof Substance] !== 0;
		});

		if (isSubstanceFormValid) {
			const newSubstances = [...substancesInfo.substances];
			newSubstances[editSubstanceId] = substance;
			updateSubstancesInfo({
				...substancesInfo,
				substances: newSubstances,
			});
		}
		// console.log('substance', substance)
		// console.log('substancesInfo', substancesInfo)

		// console.log('isSubstanceFormValid', isSubstanceFormValid)
		setSubstanceFormValid(isSubstanceFormValid);
	}, [substance]);

	// console.log('EditSubstance');
	return (
		<section className="flex flex-col gap-6 w-full">
			<div className="flex flex-col gap-3 items-center pb-4">
				<p className="text-primary font-bold text-4xl text-center">Sostanze</p>
				<p className="text-black font-medium text-xl text-center">Inserisci i dati relativi ad ogni sostanza utilizzata</p>
			</div>
			<p className="text-black font-medium text-xl">Sostanza {substance.id + 1}*</p>
			<div className="flex flex-col gap-3">
				<label className="block font-normal text-black pb-2">Denominazione*</label>
				<input
					placeholder='Testo'
					value={substance.text}
					className="border text-black border-gray-500 rounded px-4 py-2 text-sm w-full"
					onChange={(e) => {
						setSubstance({
							...substance,
							text: e.target.value,
						});
					}
					} />
			</div>
			<hr
				style={{
					border: 'none',
					borderBottom: '2px dotted gray',
					color: 'black',
					height: 10,
				}}
			/>
			<div className="flex flex-col gap-6 sm:flex-row">
				<div className="w-full h-auto sm:w-1/2">
					<label className="block font-normal text-black pb-2">Indicazione frasi di pericolo H</label>
					<Multiselect
						options={hPhrasesOptions} // Options to display in the dropdown
						onSelect={(selectedList, selectedItem) => {
							setSubstance({
								...substance,
								hPhrases: selectedList,
							});
						}} // Function will trigger on select event
						onRemove={(selectedList, removedItem) => {
							setSubstance({
								...substance,
								hPhrases: selectedList,
							});
						}} // Function will trigger on remove event
						displayValue="name" // Property name to display in the dropdown options
						placeholder='Seleziona'
						showArrow={true}
						hidePlaceholder={false}
						className='text-black text-sm'
						style={
							{
								chips: {
									backgroundColor: '#FFC300',
									color: 'black',
									fontWeight: 'normal',
									whiteSpace: 'normal !important',
								},
								singleChip: {
									whiteSpace: 'normal !important',
								},
							}
						}
					/>
				</div>
				<div className="w-full h-auto sm:w-1/2">
					<label className="block font-normal text-black pb-2">Indicazione dei consigli di prudenza P</label>
					<Multiselect
						options={pPhrasesOptions} // Options to display in the dropdown
						onSelect={(selectedList, selectedItem) => {
							setSubstance({
								...substance,
								pPhrases: selectedList,
							});
						}} // Function will trigger on select event
						onRemove={(selectedList, removedItem) => {
							setSubstance({
								...substance,
								pPhrases: selectedList,
							});
						}} // Function will trigger on remove event
						displayValue="name" // Property name to display in the dropdown options
						placeholder='Seleziona'
						showArrow={true}
						hidePlaceholder={false}
						className='text-black text-sm'
						style={
							{
								chips: {
									backgroundColor: '#FFC300',
									color: 'black',
									fontWeight: 'normal',
									whiteSpace: 'normal !important',
								},
								singleChip: {
									whiteSpace: 'normal !important',
								},
							}
						}
					/>
				</div>
			</div>
			<div className="flex flex-col gap-6 sm:flex-row">
				<div className="w-full h-auto sm:w-1/2">
					<label className="block font-normal text-black pb-2">Simboli rischio GHS</label>
					<Multiselect
						options={ghsSignalWordsOptions} // Options to display in the dropdown
						optionValueDecorator={(option) => {
							return (
								<>
									<div className="flex flex-row">
										<img src={getGHSImage(option)} alt={option} className="w-6 h-6" />
										<span className="ml-2">{option}</span>
									</div>
								</>
							)
						}}
						onSelect={(selectedList, selectedItem) => {
							setSubstance({
								...substance,
								ghsSignalWords: selectedList,
							});
						}} // Function will trigger on select event
						onRemove={(selectedList, removedItem) => {
							setSubstance({
								...substance,
								ghsSignalWords: selectedList,
							});
						}} // Function will trigger on remove event
						displayValue="name" // Property name to display in the dropdown options
						placeholder='Seleziona'
						showArrow={true}
						hidePlaceholder={false}
						className='text-black text-sm'
						style={
							{
								chips: {
									backgroundColor: '#FFC300',
									color: 'black',
									fontWeight: 'normal',
									whiteSpace: 'normal !important',
								},
								singleChip: {
									whiteSpace: 'normal !important',
								},
							}
						}
					/>
				</div>
				<div className="w-full h-auto sm:w-1/2">
					<label className="block font-normal text-black pb-2">Informazioni supplementari EUH</label>
					<Multiselect
						options={euhPhrasesOptions} // Options to display in the dropdown
						onSelect={(selectedList, selectedItem) => {
							setSubstance({
								...substance,
								euhPhrases: selectedList,
							});
						}} // Function will trigger on select event
						onRemove={(selectedList, removedItem) => {
							setSubstance({
								...substance,
								euhPhrases: selectedList,
							});
						}} // Function will trigger on remove event
						displayValue="name" // Property name to display in the dropdown options
						placeholder='Seleziona'
						showArrow={true}
						hidePlaceholder={false}
						className='text-black text-sm'
						style={
							{
								chips: {
									backgroundColor: '#FFC300',
									color: 'black',
									fontWeight: 'normal',
									whiteSpace: 'normal !important',
								},
								singleChip: {
									whiteSpace: 'normal !important',
								},
							}
						}
					/>
				</div>
			</div>
			<div className="flex flex-col sm:flex-row mr-0 sm:mr-6">
				<div className="w-full h-auto sm:w-1/2 ">
					<label className="block font-normal text-black pb-2">Codici classe</label>
					<Multiselect
						options={warningCodesOptions} // Options to display in the dropdown
						onSelect={(selectedList, selectedItem) => {
							setSubstance({
								...substance,
								warningCodes: selectedList,
							});
						}} // Function will trigger on select event
						onRemove={(selectedList, removedItem) => {
							setSubstance({
								...substance,
								warningCodes: selectedList,
							});
						}} // Function will trigger on remove event
						displayValue="name" // Property name to display in the dropdown options
						placeholder='Seleziona'
						showArrow={true}
						hidePlaceholder={false}
						className='text-black text-sm'
						style={
							{
								chips: {
									backgroundColor: '#FFC300',
									color: 'black',
									fontWeight: 'normal',
									whiteSpace: 'normal !important',
								},
								singleChip: {
									whiteSpace: 'normal !important',
								},
							}
						}
					/>
				</div>
			</div>
			<hr
				style={{
					border: 'none',
					borderBottom: '2px dotted gray',
					color: 'black',
					height: 10,
				}}
			/>
			<div className="flex flex-col gap-6 sm:flex-row">
				<div className="w-full h-auto sm:w-1/2">
					<label className="block font-normal text-black pb-2">Quantità per corpo di fabbrica*</label>
					<input
						placeholder='Quantità'
						value={substance.buildingBlockQuantity}
						type='number'
						className="border text-black border-gray-500 rounded px-4 py-2 text-sm w-full"
						onChange={(e) => {
							setSubstance({ ...substance, buildingBlockQuantity: e.target.value })
						}
						} />
				</div>
				<div className="w-full h-auto sm:w-1/2">
					<label className="block font-normal text-black pb-2">Quantità per area di stoccaggio*</label>
					<input
						placeholder='Quantità'
						type='number'
						value={substance.storageQuantity}
						className="border text-black border-gray-500 rounded px-4 py-2 text-sm w-full"
						onChange={(e) => {
							setSubstance({ ...substance, storageQuantity: e.target.value })
						}
						} />
				</div>
			</div>
			<hr
				style={{
					border: 'none',
					borderBottom: '2px dotted gray',
					color: 'black',
					height: 10,
				}}
			/>
			<div className="w-full h-auto">
				<label className="block font-normal text-black pb-2">Scheda di sicurezza (file .pdf)</label>
				<CustomDropzone />
			</div>
			<style>
				{`
				.multiSelectContainer li:hover {
					background-color: #FFC300;
					color: white;
				}
				
				.highlightOption {
					background-color: #E3B009;
					color: white;
				}

				.searchWrapper {
					margin-bottom: 10px;
				}

				.searchBox {
					padding-inline-start: 10px;
				}

				.chip, .singleChip {
					white-space: initial !important;
				  }
			`}
			</style>
		</section>
	);
};

