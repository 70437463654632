import { FormEvent, useEffect, useState } from 'react';
import { Input } from './input';
import { EmergencyContact, FactoryBody, ProductiveCycle, GenericInfo, ProductiveCycleInfo, Referent } from 'AppTypes';
import { CustomDropzone } from './customDropzone';
import React from 'react';


interface ProductiveCycleProps {
    step: number;
    setStep: (step: number) => void;
    productiveCycleInfo: ProductiveCycleInfo;
    updateProductiveCycleInfo: (productiveCycleInfo: ProductiveCycleInfo) => void;
    productiveCycleFormValid: boolean;
    setProductiveCycleFormValid: (valid: boolean) => void;
    factoryBodyFormValid: boolean;
    setFactoryBodyFormValid: (valid: boolean) => void;
}

export const ProductiveCycleForm = ({
    step,
    setStep,
    productiveCycleInfo,
    updateProductiveCycleInfo,
    productiveCycleFormValid,
    setProductiveCycleFormValid,
    factoryBodyFormValid,
    setFactoryBodyFormValid,
}: ProductiveCycleProps) => {
    useEffect(() => {
        const requiredFieldsProductiveCycle = [
            'text'
        ];

        const requiredFieldsFactoryBody = [
            'text',
            'storageArea'
        ];

        const productiveCycleValid = productiveCycleInfo.productiveCycles.every((productiveCycle) =>
            requiredFieldsProductiveCycle.every(
                (field) => productiveCycle[field as keyof ProductiveCycle]
            )
        );

        const isFactoryBodyValid = productiveCycleInfo.factoryBody.every(
            (factoryBody) => requiredFieldsFactoryBody.every(
                (field) => factoryBody[field as keyof FactoryBody]
            )
        );

        setProductiveCycleFormValid(productiveCycleValid);
        setFactoryBodyFormValid(isFactoryBodyValid);
    })

    const addProductiveCycle = () => {
        updateProductiveCycleInfo({
            ...productiveCycleInfo,
            productiveCycles: [...productiveCycleInfo.productiveCycles, { text: '' }]
        })
    }

    const updateProductiveCycle = (index: number, text: string) => {
        const productiveCycles = productiveCycleInfo.productiveCycles.map((productiveCycle, i) => {
            if (i === index) {
                return {
                    ...productiveCycle,
                    text
                }
            }
            return productiveCycle
        })
        updateProductiveCycleInfo({
            ...productiveCycleInfo,
            productiveCycles
        })
    }

    const addFactoryBody = () => {
        updateProductiveCycleInfo({
            ...productiveCycleInfo,
            factoryBody: [...productiveCycleInfo.factoryBody, { text: '', storageArea: '' }]
        })
    }

    const handleFactoryBodyInfo = (index: number, key: string, value: string) => {
        const factoryBody = productiveCycleInfo.factoryBody.map((factoryBody, i) => {
            if (i === index) {
                return {
                    ...factoryBody,
                    [key]: value
                }
            }
            return factoryBody
        })
        updateProductiveCycleInfo({
            ...productiveCycleInfo,
            factoryBody
        })
    }

    return (
        <section className="flex flex-col gap-6 w-full">
            <div className="flex flex-col gap-3 items-center pb-4">
                <p className="text-primary font-bold text-4xl text-center">Ciclo produttivo</p>
                <p className="text-black font-medium text-xl text-center">Inserisci i dati relativi ai cicli produttivi e planimetrie</p>
            </div>
            {
                productiveCycleInfo.productiveCycles.map((productiveCycle, index) => (
                    <React.Fragment key={`productive-cycle-${index}`}>
                        <div className="flex flex-col">
                            <label className="block font-normal text-black pb-2">Ciclo produttivo {index + 1}*</label>
                            <input
                                type="text"
                                value={productiveCycle.text}
                                placeholder='Testo'
                                className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
                                onChange={(e) => updateProductiveCycle(index, e.currentTarget.value)}
                            />
                            {
                                productiveCycleInfo.productiveCycles.length > 1 && index !== 0 && (
                                    <a onClick={() => {
                                        const productiveCycles = productiveCycleInfo.productiveCycles.filter((_, i) => i !== index)
                                        updateProductiveCycleInfo({
                                            ...productiveCycleInfo,
                                            productiveCycles
                                        })
                                    }} className="w-fit text-sm gap-2 pt-6 underline text-black cursor-pointer">Elimina ciclo</a>
                                )
                            }
                        </div>
                    </React.Fragment>
                ))
            }
            <div className="flex flex-row justify-end items-center">
                <a
                    className="border-0 bg-primary w-fit px-4 py-1 text-xs rounded-full flex flex-row gap-2 cursor-pointer text-black"
                    onClick={addProductiveCycle}
                >
                    Aggiungi ciclo
                </a>
            </div>
            <hr
                style={{
                    border: 'none',
                    borderBottom: '2px dotted gray',
                    color: 'black',
                    height: 10,
                }}
            />
            {
                productiveCycleInfo.factoryBody.map((factoryBody, index) => (
                    <React.Fragment key={`factory-body-${index}`}>
                        <div className="flex flex-col gap-6 sm:flex-row">
                            <div className="w-full h-auto sm:w-1/2">
                                <label className="block font-normal text-black pb-2">Corpo di fabbrica {index + 1}*</label>
                                <input
                                    type="text"
                                    value={factoryBody.text}
                                    placeholder='Testo'
                                    className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
                                    onChange={(e) => handleFactoryBodyInfo(index, 'text', e.currentTarget.value)}
                                />
                            </div>
                            <div className="w-full h-auto sm:w-1/2">
                                <label className="block font-normal text-black pb-2">Area di stoccaggio {index + 1}*</label>
                                <input
                                    type="text"
                                    value={factoryBody.storageArea}
                                    placeholder='Area di stoccaggio'
                                    className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
                                    onChange={(e) => handleFactoryBodyInfo(index, 'storageArea', e.currentTarget.value)}
                                />
                            </div>
                        </div>
                        {
                            productiveCycleInfo.factoryBody.length > 1 && index !== 0 && (
                                <a onClick={() => {
                                    const factoryBody = productiveCycleInfo.factoryBody.filter((_, i) => i !== index)
                                    updateProductiveCycleInfo({
                                        ...productiveCycleInfo,
                                        factoryBody
                                    })
                                }} className="w-fit text-sm gap-2  underline text-black cursor-pointer">Elimina ciclo</a>
                            )
                        }
                    </React.Fragment>

                ))
            }
            <div className="flex flex-row justify-end items-center">
                <a
                    className="border-0 bg-primary w-fit px-4 py-1 text-xs rounded-full flex flex-row gap-2 cursor-pointer text-black"
                    onClick={addFactoryBody}
                >
                    Aggiungi corpo
                </a>
            </div>
            <hr
                style={{
                    border: 'none',
                    borderBottom: '2px dotted gray',
                    color: 'black',
                    height: 10,
                }}
            />
            <label className="block font-medium text-xl text-black">
                Upload file*
            </label>
            <div className="flex flex-col gap-6 sm:flex-row">
                <div className="w-full h-auto sm:w-1/2">
                    <label className="block font-normal text-black pb-2 text-sm">Planimetria di stabilimento*</label>
                    <CustomDropzone />
                </div>
                <div className="w-full h-auto sm:w-1/2">
                    <label className="block font-normal text-black pb-2 text-sm">Planimetria con evidenza della viabilità interna*</label>
                    <CustomDropzone />
                </div>
            </div>
            <div className="flex flex-col gap-6 sm:flex-row">
                <div className="w-full h-auto sm:w-1/2">
                    <label className="block font-normal text-black pb-2 text-sm">Planimetria riportante le vie di fuga e il punto di ritrovo*</label>
                    <CustomDropzone />
                </div>
                <div className="w-full h-auto sm:w-1/2">
                    <label className="block font-normal text-black pb-2 text-sm">Planimetria delle cabine elettriche e dei pulsanti di emergenza*</label>
                    <CustomDropzone />
                </div>
            </div>
            <div className="flex flex-col gap-6 sm:flex-row">
                <div className="w-full h-auto sm:w-1/2">
                    <label className="block font-normal text-black pb-2 text-sm">Planimetria delle cabine gas/stazioni di decompressione*</label>
                    <CustomDropzone />
                </div>
                <div className="w-full h-auto sm:w-1/2">
                    <label className="block font-normal text-black pb-2 text-sm">Schema elettrico generale di stabilimento*</label>
                    <CustomDropzone />
                </div>
            </div>
            <div className="flex flex-col gap-6 sm:flex-row">
                <div className="w-full h-auto sm:w-1/2">
                    <label className="block font-normal text-black pb-2 text-sm">Altre planimetrie 1</label>
                    <CustomDropzone />
                </div>
                <div className="w-full h-auto sm:w-1/2">
                    <label className="block font-normal text-black pb-2 text-sm">Altre planimetrie 2</label>
                    <CustomDropzone />
                </div>
            </div>
        </section>
    );
};

