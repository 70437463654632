import { useEffect } from 'react';

const ConfirmationBanner = () => {
    const handleBeforeUnload = (e: any) => {
        e.preventDefault();
        e.returnValue = '';
    };

    useEffect(() => {
        // Gestione per Safari
        window.onbeforeunload = handleBeforeUnload;
        // window.onunload = handleBeforeUnload;
        // window.onpopstate = handleBeforeUnload;

        // Gestione per gli altri browser
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // Rimozione gestione per Safari
            window.onbeforeunload = null;
            // window.onunload = null;
            // window.onpopstate = null;

            // Rimozione gestione per gli altri browser
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return null;
};

export default ConfirmationBanner;