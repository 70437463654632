export const hPhrases = [
    {
        codice: "H200",
        frase: "Esplosivo instabile",
    },
    {
        codice: "H201",
        frase: "Esplosivo; pericolo di esplosione di massa",
    },
    {
        codice: "H202",
        frase: "Esplosivo; grave pericolo di proiezione",
    },
    {
        codice: "H203",
        frase:
            "Esplosivo; pericolo di incendio, di spostamento d'aria o di proiezione",
    },
    {
        codice: "H204",
        frase: "Pericolo di incendio o di proiezione",
    },
    {
        codice: "H205",
        frase: "Pericolo di esplosione di massa in caso d'incendio",
    },
    {
        codice: "H220",
        frase: "Gas altamente infiammabile",
    },
    {
        codice: "H221",
        frase: "Gas infiammabile",
    },
    {
        codice: "H222",
        frase: "Aerosol altamente infiammabile",
    },
    {
        codice: "H223",
        frase: "Aerosol infiammabile",
    },
    {
        codice: "H224",
        frase: "Liquido e vapori altamente infiammabili",
    },
    {
        codice: "H225",
        frase: "Liquido e vapori facilmente infiammabili",
    },
    {
        codice: "H226",
        frase: "Liquido e vapori infiammabili",
    },
    {
        codice: "H228",
        frase: "Solido infiammabile",
    },
    {
        codice: "H240",
        frase: "Rischio di esplosione per riscaldamento",
    },
    {
        codice: "H241",
        frase: "Rischio d'incendio o di esplosione per riscaldamento",
    },
    {
        codice: "H242",
        frase: "Rischio d'incendio per riscaldamento",
    },
    {
        codice: "H250",
        frase: "Spontaneamente infiammabile all'aria",
    },
    {
        codice: "H251",
        frase: "Autoriscaldante; può infiammarsi",
    },
    {
        codice: "H252",
        frase: "Autoriscaldante in grandi quantità; può infiammarsi",
    },
    {
        codice: "H260",
        frase:
            "A contatto con l'acqua libera gas infiammabili che possono infiammarsi spontaneamente",
    },
    {
        codice: "H261",
        frase: "A contatto con l'acqua libera gas infiammabili",
    },
    {
        codice: "H270",
        frase: "Può provocare o aggravare un incendio; comburente",
    },
    {
        codice: "H271",
        frase: "Può provocare un incendio o un'esplosione; molto comburente",
    },
    {
        codice: "H272",
        frase: "Può aggravare un incendio; comburente",
    },
    {
        codice: "H280",
        frase: "Contiene gas sotto pressione; può esplodere se riscaldato",
    },
    {
        codice: "H281",
        frase:
            "Contiene gas refrigerato; può provocare ustioni o lesioni criogeniche",
    },
    {
        codice: "H290",
        frase: "Può essere corrosivo per i metalli",
    },
    {
        codice: "H300",
        frase: "Letale se ingerito",
    },
    {
        codice: "H301",
        frase: "Tossico se ingerito",
    },
    {
        codice: "H302",
        frase: "Nocivo se ingerito",
    },
    {
        codice: "H304",
        frase:
            "Può essere letale in caso di ingestione e di penetrazione nelle vie respiratorie",
    },
    {
        codice: "H310",
        frase: "Letale per contatto con la pelle",
    },
    {
        codice: "H311",
        frase: "Tossico per contatto con la pelle",
    },
    {
        codice: "H312",
        frase: "Nocivo per contatto con la pelle",
    },
    {
        codice: "H314",
        frase: "Provoca gravi ustioni cutanee e gravi lesioni oculari",
    },
    {
        codice: "H315",
        frase: "Provoca irritazione cutanea",
    },
    {
        codice: "H317",
        frase: "Può provocare una reazione allergica cutanea",
    },
    {
        codice: "H318",
        frase: "Provoca gravi lesioni oculari",
    },
    {
        codice: "H319",
        frase: "Provoca grave irritazione oculare",
    },
    {
        codice: "H330",
        frase: "Letale se inalato",
    },
    {
        codice: "H331",
        frase: "Tossico se inalato",
    },
    {
        codice: "H332",
        frase: "Nocivo se inalato",
    },
    {
        codice: "H334",
        frase:
            "Può provocare sintomi allergici o asmatici o difficoltà respiratorie se inalato",
    },
    {
        codice: "H335",
        frase: "Può irritare le vie respiratorie",
    },
    {
        codice: "H336",
        frase: "Può provocare sonnolenza o vertigini",
    },
    {
        codice: "H340",
        frase:
            "Può provocare alterazioni genetiche <indicare la via di esposizione se è accertato che nessun'altra via di esposizione comporta il medesimo pericolo>",
    },
    {
        codice: "H341",
        frase:
            "Sospettato di provocare alterazioni genetiche <indicare la via di esposizione se è accertato che nessun'altra via di esposizione comporta il medesimo pericolo>",
    },
    {
        codice: "H350",
        frase:
            "Può provocare il cancro <indicare la via di esposizione se è accertato che nessun'altra via di esposizione comporta il medesimo pericolo>",
    },
    {
        codice: "H351",
        frase:
            "Sospettato di provocare il cancro <indicare la via di esposizione se è accertato che nessun'altra via di esposizione comporta il medesimo pericolo>",
    },
    {
        codice: "H360",
        frase:
            "Può nuocere alla fertilità o al feto <indicare l'effetto specifico, se noto> <indicare la via di esposizione se è accertato che nessun'altra via di esposizione comporta il medesimo pericolo>",
    },
    {
        codice: "H361",
        frase:
            "Sospettato di nuocere alla fertilità o al feto <indicare l'effetto specifico, se noto> <indicare la via di esposizione se è accertato che nessun'altra via di esposizione comporta il medesimo pericolo>",
    },
    {
        codice: "H362",
        frase: "Può essere nocivo per i lattanti allattati al seno",
    },
    {
        codice: "H370",
        frase:
            "Provoca danni agli organi <o indicare tutti gli organi interessati, se noti> <indicare la via di esposizione se è accertato che nessun'altra via di esposizione comporta il medesimo pericolo>",
    },
    {
        codice: "H371",
        frase:
            "Può provocare danni agli organi <o indicare tutti gli organi interessati, se noti> <indicare la via di esposizione se è accertato che nessun'altra via di esposizione comporta il medesimo pericolo>",
    },
    {
        codice: "H372",
        frase:
            "Provoca danni agli organi <o indicare tutti gli organi interessati, se noti> in caso di esposizione prolungata o ripetuta <indicare la via di esposizione se è accertato che nessun'altra via di esposizione comporta il medesimo pericolo>",
    },
    {
        codice: "H373",
        frase:
            "Può provocare danni agli organi <o indicare tutti gli organi interessati, se noti> in caso di esposizione prolungata o ripetuta <indicare la via di esposizione se è accertato che nessun'altra via di esposizione comporta il medesimo pericolo>",
    },
    {
        codice: "H400",
        frase: "Molto tossico per gli organismi acquatici",
    },
    {
        codice: "H410",
        frase:
            "Molto tossico per gli organismi acquatici con effetti di lunga durata",
    },
    {
        codice: "H411",
        frase: "Tossico per gli organismi acquatici con effetti di lunga durata",
    },
    {
        codice: "H412",
        frase: "Nocivo per gli organismi acquatici con effetti di lunga durata",
    },
    {
        codice: "H413",
        frase:
            "Può essere nocivo per gli organismi acquatici con effetti di lunga durata",
    },
];

export const pPhrases = [
    {
        codice: "P101",
        frase:
            "In caso di consultazione di un medico, tenere a disposizione il contenitore o l'etichetta del prodotto.",
    },
    {
        codice: "P102",
        frase: "Tenere fuori dalla portata dei bambini.",
    },
    {
        codice: "P103",
        frase: "Leggere l’etichetta prima dell’uso.",
    },
    {
        codice: "P201",
        frase: "Procurarsi istruzioni specifiche prima dell’uso.",
    },
    {
        codice: "P202",
        frase:
            "Non manipolare prima di avere letto e compreso tutte le avvertenze.",
    },
    {
        codice: "P210",
        frase:
            "Tenere lontano da fonti di calore, superfici calde, scintille, fiamme libere o altre fonti di accensione. Non fumare.",
    },
    {
        codice: "P211",
        frase: "Non vaporizzare su una fiamma libera o altra fonte di accensione.",
    },
    {
        codice: "P220",
        frase: "Tenere lontano da indumenti e altri materiali combustibili.",
    },
    {
        codice: "P222",
        frase: "Evitare il contatto con l’aria.",
    },
    {
        codice: "P223",
        frase: "Evitare qualunque contatto con l’acqua.",
    },
    {
        codice: "P230",
        frase: "Mantenere umido con...",
    },
    {
        codice: "P231",
        frase: "Manipolare e conservare in atmosfera di gas inerte/…",
    },
    {
        codice: "P232",
        frase: "Proteggere dall’umidità.",
    },
    {
        codice: "P233",
        frase: "Tenere il recipiente ben chiuso.",
    },
    {
        codice: "P234",
        frase: "Conservare soltanto nell'imballaggio originale.",
    },
    {
        codice: "P235",
        frase: "Conservare in luogo fresco.",
    },
    {
        codice: "P240",
        frase:
            "Mettere a terra e a massa il contenitore e il dispositivo ricevente.",
    },
    {
        codice: "P241",
        frase:
            "Utilizzare impianti [elettrici/di ventilazione/d'illuminazione/…] a prova di esplosione.",
    },
    {
        codice: "P242",
        frase: "Utilizzare utensili antiscintillamento.",
    },
    {
        codice: "P243",
        frase: "Fare in modo di prevenire le scariche elettrostatiche.",
    },
    {
        codice: "P244",
        frase: "Mantenere le valvole e i raccordi liberi da olio e grasso.",
    },
    {
        codice: "P250",
        frase: "Evitare le abrasioni/gli urti/gli attriti/…",
    },
    {
        codice: "P251",
        frase: "Non perforare né bruciare, neppure dopo l’uso.",
    },
    {
        codice: "P260",
        frase:
            "Non respirare la polvere/i fumi/i gas/la nebbia/i vapori/gli aerosol.",
    },
    {
        codice: "P261",
        frase:
            "Evitare di respirare la polvere/i fumi/i gas/la nebbia/i vapori/gli aerosol.",
    },
    {
        codice: "P262",
        frase: "Evitare il contatto con gli occhi, la pelle o gli indumenti.",
    },
    {
        codice: "P263",
        frase: "Evitare il contatto durante la gravidanza e l'allattamento.",
    },
    {
        codice: "P264",
        frase: "Lavare accuratamente ... dopo l’uso.",
    },
    {
        codice: "P270",
        frase: "Non mangiare, né bere, né fumare durante l’uso.",
    },
    {
        codice: "P271",
        frase: "Utilizzare soltanto all’aperto o in luogo ben ventilato.",
    },
    {
        codice: "P272",
        frase:
            "Gli indumenti da lavoro contaminati non devono essere portati fuori dal luogo di lavoro.",
    },
    {
        codice: "P273",
        frase: "Non disperdere nell’ambiente.",
    },
    {
        codice: "P280",
        frase:
            "Indossare guanti/indumenti protettivi/Proteggere gli occhi/il viso.",
    },
    {
        codice: "P282",
        frase:
            "Utilizzare guanti termici e schermo facciale o protezione per gli occhi.",
    },
    {
        codice: "P283",
        frase:
            "Indossare indumenti completamente ignifughi o in tessuti ritardanti di fiamma.",
    },
    {
        codice: "P284",
        frase:
            "[Quando la ventilazione del locale è insufficiente] indossare un apparecchio di protezione respiratoria.",
    },
    {
        codice: "P231 + P232",
        frase:
            "Manipolare e conservare in atmosfera di gas inerte/…. Tenere al riparo dall'umidità.",
    },
    {
        codice: "P301",
        frase: "IN CASO DI INGESTIONE:",
    },
    {
        codice: "P302",
        frase: "IN CASO DI CONTATTO CON LA PELLE:",
    },
    {
        codice: "P303",
        frase: "IN CASO DI CONTATTO CON LA PELLE (o con i capelli):",
    },
    {
        codice: "P304",
        frase: "IN CASO DI INALAZIONE:",
    },
    {
        codice: "P305",
        frase: "IN CASO DI CONTATTO CON GLI OCCHI:",
    },
    {
        codice: "P306",
        frase: "IN CASO DI CONTATTO CON GLI INDUMENTI:",
    },
    {
        codice: "P308",
        frase: "IN CASO di esposizione o di possibile esposizione:",
    },
    {
        codice: "P310",
        frase: "Contattare immediatamente un CENTRO ANTIVELENI/un medico…",
    },
    {
        codice: "P311",
        frase: "Contattare un CENTRO ANTIVELENI/un medico/…",
    },
    {
        codice: "P312",
        frase:
            "In caso di malessere, contattare un CENTRO ANTIVELENI/un medico/… .",
    },
    {
        codice: "P313",
        frase: "Consultare un medico.",
    },
    {
        codice: "P314",
        frase: "In caso di malessere, consultare un medico.",
    },
    {
        codice: "P315",
        frase: "Consultare immediatamente un medico.",
    },
    {
        codice: "P320",
        frase: "Trattamento specifico urgente (vedere…… su questa etichetta).",
    },
    {
        codice: "P321",
        frase: "Trattamento specifico (vedere ……su questa etichetta).",
    },
    {
        codice: "P330",
        frase: "Sciacquare la bocca.",
    },
    {
        codice: "P331",
        frase: "NON provocare il vomito.",
    },
    {
        codice: "P332",
        frase: "In caso di irritazione della pelle:",
    },
    {
        codice: "P333",
        frase: "In caso di irritazione o eruzione della pelle:",
    },
    {
        codice: "P334",
        frase: "Immergere in acqua fredda [o avvolgere con un bendaggio umido].",
    },
    {
        codice: "P335",
        frase: "Rimuovere le particelle depositate sulla pelle.",
    },
    {
        codice: "P336",
        frase:
            "Sgelare le parti congelate usando acqua tiepida. Non sfregare la parte interessata.",
    },
    {
        codice: "P337",
        frase: "Se l’irritazione degli occhi persiste:",
    },
    {
        codice: "P338",
        frase:
            "Togliere le eventuali lenti a contatto se è agevole farlo. Continuare a sciacquare.",
    },
    {
        codice: "P340",
        frase:
            "Trasportare l'infortunato all’aria aperta e mantenerlo a riposo in posizione che favorisca la respirazione.",
    },
    {
        codice: "P342",
        frase: "In caso di sintomi respiratori:",
    },
    {
        codice: "P351",
        frase: "Sciacquare accuratamente per parecchi minuti",
    },
    {
        codice: "P352",
        frase: "Lavare abbondantemente con acqua/…",
    },
    {
        codice: "P353",
        frase: "Sciacquare la pelle [o fare una doccia].",
    },
    {
        codice: "P360",
        frase:
            "Sciacquare immediatamente e abbondantemente gli indumenti contaminati e la pelle prima di togliersi gli indumenti.",
    },
    {
        codice: "P361",
        frase: "Togliere immediatamente tutti gli indumenti contaminati.",
    },
    {
        codice: "P362",
        frase: "Togliere gli indumenti contaminati.",
    },
    {
        codice: "P363",
        frase: "Lavare gli indumenti contaminati prima di indossarli nuovamente.",
    },
    {
        codice: "P370",
        frase: "In caso di incendio:",
    },
    {
        codice: "P371",
        frase: "In caso di incendio grave e di quantità rilevanti:",
    },
    {
        codice: "P372",
        frase: "Rischio di esplosione in caso di incendio.",
    },
    {
        codice: "P373",
        frase:
            "NON utilizzare mezzi estinguenti se l’incendio raggiunge materiali esplosivi.",
    },
    {
        codice: "P375",
        frase:
            "Rischio di esplosione. Utilizzare i mezzi estinguenti a grande distanza.",
    },
    {
        codice: "P376",
        frase: "Bloccare la perdita se non c’è pericolo.",
    },
    {
        codice: "P377",
        frase:
            "In caso d’incendio dovuto a perdita di gas, non estinguere a meno che non sia possibile bloccare la perdita senza pericolo.",
    },
    {
        codice: "P378",
        frase: "Utilizzare….per estinguere.",
    },
    {
        codice: "P380",
        frase: "Evacuare la zona.",
    },
    {
        codice: "P381",
        frase: "In caso di perdita, eliminare ogni fonte di accensione.",
    },
    {
        codice: "P390",
        frase: "Assorbire la fuoriuscita per evitare danni materiali.",
    },
    {
        codice: "P391",
        frase: "Raccogliere il materiale fuoriuscito.",
    },
    {
        codice: "P301 + P310",
        frase:
            "IN CASO DI INGESTIONE: contattare immediatamente un CENTRO ANTIVELENI/un medico/…",
    },
    {
        codice: "P301 + P312",
        frase:
            "IN CASO DI INGESTIONE: in presenza di malessere, contattare un CENTRO ANTIVELENI/un medico/… .",
    },
    {
        codice: "P301 + P330 + P331",
        frase:
            "IN CASO DI INGESTIONE: sciacquare la bocca. NON provocare il vomito.",
    },

    {
        codice: "P302 + P334",
        frase:
            "IN CASO DI CONTATTO CON LA PELLE: immergere in acqua fredda o avvolgere con un bendaggio umido.",
    },
    {
        codice: "P302 + P352",
        frase:
            "IN CASO DI CONTATTO CON LA PELLE: lavare abbondantemente con acqua/…",
    },
    {
        codice: "P303 + P361 + P353",
        frase:
            "IN CASO DI CONTATTO CON LA PELLE (o con i capelli): togliersi di dosso immediatamente tutti gli indumenti contaminati. Sciacquare la pelle [o fare una doccia].",
    },
    {
        codice: "P304 + P340",
        frase:
            "IN CASO DI INALAZIONE: trasportare l'infortunato all’aria aperta e mantenerlo a riposo in posizione che favorisca la respirazione.",
    },
    {
        codice: "P305 + P351 + P338",
        frase:
            "IN CASO DI CONTATTO CON GLI OCCHI: sciacquare accuratamente per parecchi minuti. Togliere le eventuali lenti a contatto se è agevole farlo. Continuare a sciacquare.",
    },
    {
        codice: "P306 + P360",
        frase:
            "IN CASO DI CONTATTO CON GLI INDUMENTI: sciacquare immediatamente e abbondantemente gli indumenti contaminati e la pelle prima di togliersi gli indumenti.",
    },
    {
        codice: "P308 + P313",
        frase:
            "IN CASO di esposizione o di possibile esposizione, consultare un medico.",
    },
    {
        codice: "P332 + P313",
        frase: "In caso di irritazione della pelle: consultare un medico.",
    },
    {
        codice: "P333 + P313",
        frase:
            "In caso di irritazione o eruzione della pelle: consultare un medico.",
    },
    {
        codice: "P337 + P313",
        frase: "Se l’irritazione degli occhi persiste, consultare un medico.",
    },
    {
        codice: "P342 + P311",
        frase:
            "In caso di sintomi respiratori: contattare un CENTRO ANTIVELENI/un medico/…",
    },
    {
        codice: "P370 + P376",
        frase: "In caso di incendio: bloccare la perdita se non c’è pericolo.",
    },
    {
        codice: "P370 + P378",
        frase: "In caso d’incendio: utilizzare…per estinguere.",
    },
    {
        codice: "P370 + P380 + P375",
        frase:
            "In caso di incendio: evacuare la zona. Rischio di esplosione. Utilizzare i mezzi estinguenti a grande distanza.",
    },
    {
        codice: "P371 + P380 + P375",
        frase:
            "In caso di incendio grave e di grandi quantità: evacuare la zona. Rischio di esplosione. Utilizzare i mezzi estinguenti a grande distanza.",
    },
    { codice: "P401", frase: "Conservare secondo… ." },
    { codice: "P402", frase: "Conservare in luogo asciutto." },
    { codice: "P403", frase: "Conservare in luogo ben ventilato." },
    { codice: "P404", frase: "Conservare in un recipiente chiuso." },
    { codice: "P405", frase: "Conservare sotto chiave." },
    {
        codice: "P406",
        frase:
            "Conservare in recipiente resistente alla corrosione/... provvisto di rivestimento interno resistente.",
    },
    {
        codice: "P407",
        frase: "Mantenere uno spazio libero tra gli scaffali o i pallet.",
    },
    { codice: "P410", frase: "Proteggere dai raggi solari." },
    {
        codice: "P411",
        frase: "Conservare a temperature non superiori a ... °C/...°F.",
    },
    {
        codice: "P412",
        frase: "Non esporre a temperature superiori a 50 °C/122°F.",
    },
    {
        codice: "P413",
        frase:
            "Conservare le rinfuse di peso superiore a ...kg/...lb a temperature non superiori a ... °C/°F.",
    },
    { codice: "P420", frase: "Conservare separatamente." },
    {
        codice: "P402 + P404",
        frase: "Conservare in luogo asciutto e in recipiente chiuso.",
    },
    {
        codice: "P403 + P233",
        frase: "Tenere il recipiente ben chiuso e in luogo ben ventilato.",
    },
    {
        codice: "P403 + P235",
        frase: "Conservare in luogo fresco e ben ventilato.",
    },
    {
        codice: "P410 + P403",
        frase: "Proteggere dai raggi solari. Conservare in luogo ben ventilato.",
    },
    {
        codice: "P410 + P412",
        frase:
            "Proteggere dai raggi solari. Non esporre a temperature superiori a 50 °C/122°F.",
    },
    { codice: "P501", frase: "Smaltire il prodotto/recipiente in ..." },
];

export const ghsSignalWords = [
    {
        immagine:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/GHS-pictogram-explos.svg/100px-GHS-pictogram-explos.svg.png",
        codice: "GHS01",
        frase: "Esplosivo",
    },
    {
        immagine:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/GHS-pictogram-flamme.svg/100px-GHS-pictogram-flamme.svg.png",
        codice: "GHS02",
        frase: "Infiammabile",
    },
    {
        immagine:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/GHS-pictogram-rondflam.svg/100px-GHS-pictogram-rondflam.svg.png",
        codice: "GHS03",
        frase: "Comburente",
    },
    {
        immagine:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/GHS-pictogram-bottle.svg/100px-GHS-pictogram-bottle.svg.png",
        codice: "GHS04",
        frase: "Gas compressi",
    },
    {
        immagine:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/GHS-pictogram-acid.svg/100px-GHS-pictogram-acid.svg.png",
        codice: "GHS05",
        frase: "Corrosivo",
    },
    {
        immagine:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/GHS-pictogram-skull.svg/100px-GHS-pictogram-skull.svg.png",
        codice: "GHS06",
        frase: "Tossico",
    },
    {
        immagine:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/GHS-pictogram-exclam.svg/100px-GHS-pictogram-exclam.svg.png",
        codice: "GHS07",
        frase: "Irritante",
    },
    {
        immagine:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/GHS-pictogram-silhouette.svg/100px-GHS-pictogram-silhouette.svg.png",
        codice: "GHS08",
        frase: "Tossico a lungo termine",
    },
    {
        immagine:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/GHS-pictogram-pollu.svg/100px-GHS-pictogram-pollu.svg.png",
        codice: "GHS09",
        frase: "Pericoloso per l'ambiente",
    },
];

export const euhPhrases = [
    {
        codice: "EUH 001",
        frase: "Esplosivo allo stato secco.",
    },
    {
        codice: "EUH 014",
        frase: "Reagisce violentemente con l'acqua.",
    },
    {
        codice: "EUH 018",
        frase: "Durante l'uso può formarsi una miscela vapore-aria esplosiva/infiammabile.",
    },
    {
        codice: "EUH 019",
        frase: "Può formare perossidi esplosivi.",
    },
    {
        codice: "EUH 044",
        frase: "Rischio di esplosione per riscaldamento in ambiente confinato.",
    },
    {
        codice: "EUH 029",
        frase: "A contatto con l'acqua libera un gas tossico."
    },
    {
        codice: "EUH 031",
        frase: "A contatto con acidi libera un gas tossico."
    },
    {
        codice: "EUH 032",
        frase: "A contatto con acidi libera gas molto tossici.",
    },
    {
        codice: "EUH 066",
        frase: "L'esposizione ripetuta può provocare secchezza o screpolature della pelle.",
    },
    {
        codice: "EUH 070",
        frase: "Tossico per contatto oculare."
    },
    {
        codice: "EUH 071",
        frase: "Corrosivo per le vie respiratorie."
    },
];

export const warningCodes = [
    {
        nome: "Acute Tox. 1",
        frase: "Tossicità acuta Categoria di pericolo 1",
        avvertenza: "Pericolo",
    },
    // Da chiedere a Luca
]