import { FormEvent, useEffect, useState } from 'react';
import { Input } from './input';
import { EmergencyContact, GenericInfo, Referent } from 'AppTypes';
import React from 'react';

interface SignupProps {
	step: number;
	setStep: (step: number) => void;
	genericInfo: GenericInfo;
	updateGenericInfo: (genericInfo: GenericInfo) => void;
	genericInfoFormValid: boolean;
	setGenericInfoFormValid: (valid: boolean) => void;
	referentFormValid: boolean;
	setReferentFormValid: (valid: boolean) => void;
	emergencyContactFormValid: boolean;
	setEmergencyContactFormValid: (valid: boolean) => void;
}

export const Signup = ({
	step,
	setStep,
	genericInfo,
	updateGenericInfo,
	genericInfoFormValid,
	setGenericInfoFormValid,
	referentFormValid,
	setReferentFormValid,
	emergencyContactFormValid,
	setEmergencyContactFormValid,
}: SignupProps) => {
	const [referentIndex, setReferentIndex] = useState<number>(1);
	const [emergencyContactIndex, setEmergencyContactIndex] = useState<number>(1);

	useEffect(() => {
		const requiredFieldsGenericInfo: Array<keyof GenericInfo> = [
			'companyName',
			'companyAddress',
			'atecoCode',
			'address',
		];

		const requiredFieldsReferent: Array<keyof Referent> = [
			'name',
			'surname',
			'phone',
		];

		const requiredFieldsEmergencyContact: Array<keyof EmergencyContact> = [
			'name',
			'phone',
		];

		const isGenericInfoValid = requiredFieldsGenericInfo.every(
			(field) => genericInfo[field as keyof GenericInfo]
		);

		const isReferentValid = genericInfo.referents.every((referent) =>
			requiredFieldsReferent.every(
				(field) => referent[field as keyof Referent]
			)
		);

		const isEmergencyContactValid = genericInfo.emergencyContacts.every(
			(emergencyContact) =>
				requiredFieldsEmergencyContact.every(
					(field) => emergencyContact[field as keyof EmergencyContact]
				)
		);

		setGenericInfoFormValid(isGenericInfoValid);
		setReferentFormValid(isReferentValid);
		setEmergencyContactFormValid(isEmergencyContactValid);
	}, [genericInfo, updateGenericInfo]);

	const handleGenericInfo = (
		event: FormEvent<HTMLInputElement>,
		key: keyof GenericInfo
	) => {
		if (key !== 'referents' && key !== 'emergencyContacts') {
			const updatedGenericInfo = { ...genericInfo };
			updatedGenericInfo[key] = event.currentTarget.value;
			updateGenericInfo(updatedGenericInfo);
			return;
		}
	};


	const handleReferentInfo = (
		event: FormEvent<HTMLInputElement>,
		referentIndex: number,
		key: keyof Referent
	) => {
		const updatedGenericInfo = { ...genericInfo };
		updatedGenericInfo.referents[referentIndex][key] = event.currentTarget.value;
		updateGenericInfo(updatedGenericInfo);
	};

	const handleEmergencyContactInfo = (
		event: FormEvent<HTMLInputElement>,
		emergencyContactIndex: number,
		key: keyof EmergencyContact
	) => {
		const updatedGenericInfo = { ...genericInfo };
		updatedGenericInfo.emergencyContacts[emergencyContactIndex][key] = event.currentTarget.value;
		updateGenericInfo(updatedGenericInfo);
	};

	const addReferent = () => {
		const updatedGenericInfo = { ...genericInfo };
		updatedGenericInfo.referents.push({ name: '', surname: '', phone: '' });
		updateGenericInfo(updatedGenericInfo);
		setReferentIndex(genericInfo.referents.length);
	};

	const addEmergencyContact = () => {
		const updatedGenericInfo = { ...genericInfo };
		updatedGenericInfo.emergencyContacts.push({ name: '', phone: '' });
		updateGenericInfo(updatedGenericInfo);
	};

	return (
		<section className="flex flex-col gap-6 w-full">
			<div className="flex flex-col gap-3 items-center pb-4">
				<p className="text-primary font-bold text-4xl text-center">Registrazione Utente</p>
				<p className="text-black font-medium text-xl text-center">Inserisci i dati generali</p>
			</div>

			<div className="flex flex-col gap-6 sm:flex-row">
				<div className="w-full h-auto sm:w-1/2">
					<label className="block font-normal text-black pb-2">Nome Azienda*</label>
					<input
						placeholder="Nome*"
						value={genericInfo.companyName}
						className="border text-black border-gray-500 rounded px-4 py-2 text-sm w-full"
						onChange={(e) => handleGenericInfo(e, 'companyName')}
					/>
				</div>
				<div className="w-full h-auto sm:w-1/2">
					<label className="block font-normal text-black pb-2">Codice Ateco*</label>
					<input
						placeholder="Codice*"
						value={genericInfo.atecoCode}
						className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
						onChange={(e) => handleGenericInfo(e, 'atecoCode')}
					/>
				</div>
			</div>
			<div className="flex flex-col gap-6 sm:flex-row">
				<div className="w-full h-auto">
					<label className="block font-normal text-black pb-2">Indirizzo*</label>
					<input
						placeholder="Indirizzo"
						value={genericInfo.companyAddress}
						className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
						onChange={(e) => handleGenericInfo(e, 'companyAddress')}
					/>
				</div>
			</div>
			<hr
				style={{
					border: 'none',
					borderBottom: '2px dotted gray',
					color: 'black',
					height: 10,
				}}
			/>
			<label className="block font-medium text-xl text-black">
				Dati dei referenti da contattare in caso di emergenza*
			</label>
			{genericInfo.referents.map((referent, index) => (
				<React.Fragment key={`referent-${index}`}>
					<div key={index} className="flex flex-col gap-6 sm:flex-row">
						<div className="w-full h-auto sm:w-1/3">
							<label className="block font-normal text-black pb-2">Nome referente {index + 1}*</label>
							<input
								placeholder="Nome*"
								value={referent.name}
								onChange={(e) => handleReferentInfo(e, index, 'name')}
								className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
							/>
						</div>
						<div className="w-full h-auto sm:w-1/3">
							<label className="block font-normal text-black pb-2">Cognome referente {index + 1}*</label>
							<input
								placeholder="Cognome*"
								value={referent.surname}
								onChange={(e) => handleReferentInfo(e, index, 'surname')}
								className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
							/>
						</div>
						<div className="w-full h-auto sm:w-1/3">
							<label className="block font-normal text-black pb-2">Telefono referente {index + 1}*</label>
							<input
								placeholder="Telefono*"
								value={referent.phone}
								onChange={(e) => handleReferentInfo(e, index, 'phone')}
								className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
							/>
						</div>
					</div>
					{
						genericInfo.referents.length > 1 && index !== 0 && (
							<a onClick={() => {
								const updatedGenericInfo = { ...genericInfo };
								updatedGenericInfo.referents.splice(index, 1);
								updateGenericInfo(updatedGenericInfo);
							}} className="w-fit  text-sm gap-2 underline text-black cursor-pointer">Elimina contatto</a>
						)
					}
				</React.Fragment>
			))}
			<div className="flex flex-row justify-end items-center">
				<a
					className="border-0 bg-primary w-fit px-4 py-1 text-xs rounded-full flex flex-row gap-2 cursor-pointer text-black"
					onClick={addReferent}
				>
					Aggiungi contatto{' '}
				</a>
			</div>
			<hr
				style={{
					border: 'none',
					borderBottom: '2px dotted gray',
					color: 'black',
					height: 10,
				}}
			/>
			<label className="block font-medium text-xl text-black">
				Recapiti telefonici di emergenza (es. 112, 113, 118...)*
			</label>
			{genericInfo.emergencyContacts.map((emergencyContact, index) => (
				<React.Fragment key={`emergency-contact-${index}`}>
					<div key={index} className="flex flex-col gap-6 sm:flex-row">
						<div className="w-full h-auto sm:w-1/2">
							<label className="block font-normal text-black pb-2">Nome recapito {index + 1}*</label>
							<input
								placeholder="Nome*"
								value={emergencyContact.name}
								onChange={(e) => handleEmergencyContactInfo(e, index, 'name')}
								className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
							/>
						</div>
						<div className="w-full h-auto sm:w-1/2">
							<label className="block font-normal text-black pb-2">Numero recapito {index + 1}*</label>
							<input
								placeholder="Numero*"
								value={emergencyContact.phone}
								onChange={(e) => handleEmergencyContactInfo(e, index, 'phone')}
								className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
							/>
						</div>
					</div>
					{
						genericInfo.emergencyContacts.length > 1 && index !== 0 && (
							<a onClick={() => {
								const updatedGenericInfo = { ...genericInfo };
								updatedGenericInfo.emergencyContacts.splice(index, 1);
								updateGenericInfo(updatedGenericInfo);
							}} className="w-fit  text-sm gap-2 underline text-black cursor-pointer">Elimina contatto</a>
						)
					}
				</React.Fragment>
			))}
			<div className="flex flex-row justify-end items-center">
				<a
					className="border-0 bg-primary w-fit px-4 py-1 text-xs rounded-full flex flex-row gap-2 cursor-pointer text-black"
					onClick={addEmergencyContact}
				>
					Aggiungi contatto{' '}
				</a>
			</div>
			<hr
				style={{
					border: 'none',
					borderBottom: '2px dotted gray',
					color: 'black',
					height: 10,
				}}
			/>
			<label className="block font-medium text-xl text-black">
				Indirizzo*
			</label>
			<div className="flex flex-row gap-6">
				<div className="w-full h-auto">
					<input
						placeholder="Indirizzo"
						value={genericInfo.address}
						onChange={(e) => handleGenericInfo(e, 'address')}
						className="border text-black border-gray-800 rounded px-4 py-2 text-sm w-full"
					/>
				</div>
				<div className="h-auto border flex items-center justify-center border-black px-3 rounded-md cursor-pointer hover:bg-primary hover:border-primary">
					<svg fill="#000000" version="1.1" id="Capa_1"
						width="20px" height="20px" viewBox="0 0 395.71 395.71">
						<g>
							<path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
		c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
		C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
		c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"/>
						</g>
					</svg>
				</div>
			</div>
		</section>
	);
};

