import { FormEvent, useEffect, useState } from 'react';
import { Input } from './input';
import { EmergencyContact, GenericInfo, Referent, Substance, SubstancesInfo } from 'AppTypes';
import React from 'react';

interface SubstancesProps {
	step: number;
	setStep: (step: number) => void;
	substancesInfo: SubstancesInfo;
	updateSubstancesInfo: (substancesInfo: SubstancesInfo) => void;
	substancesEditMode: boolean;
	setSubstancesEditMode: (editSubstancesMode: boolean) => void;
	editSubstanceId: number | undefined;
	setEditSubstanceId: (editSubstanceId: number) => void;
}

export const SubstancesForm = ({
	step,
	setStep,
	substancesInfo,
	updateSubstancesInfo,
	substancesEditMode,
	setSubstancesEditMode,
	editSubstanceId,
	setEditSubstanceId,
}: SubstancesProps) => {

	// console.log('SubstancesForm');
	const addSubstance = (index: number) => {
		const newSubstances = [...substancesInfo.substances];
		const newSubstance: Substance = {
			id: index,
			text: '',
			buildingBlockQuantity: '',
			storageQuantity: '',
			hPhrases: [],
			pPhrases: [],
			euhPhrases: [],
			ghsSignalWords: [],
			warningCodes: [],
		}
		newSubstances.push(newSubstance);
		updateSubstancesInfo({
			...substancesInfo,
			substances: newSubstances,
		});

	}

	return (
		<section className="flex flex-col gap-6 w-full">
			<div className="flex flex-col gap-3 items-center pb-4">
				<p className="text-primary font-bold text-4xl text-center">Sostanze</p>
				<p className="text-black font-medium text-xl text-center">Inserisci i dati relativi ad ogni sostanza utilizzata</p>
			</div>
			{
				substancesInfo.substances.map((substance, index) => {
					return (

						substance.text === '' && substancesInfo.substances.length === 1 && ( // Casistica in cui non è stata ancora inserita nessuna sostanza
						<React.Fragment key={`substance-default-${index}`}>
							<div className="flex flex-col gap-6">
								<p className="text-black font-medium text-xl">Sostanza {index + 1}*</p>
								<a
									className="border-0 bg-primary w-fit px-4 py-1 text-xs rounded-full flex flex-row gap-2 cursor-pointer text-black"
									onClick={() => {
										// console.log('aggiungi sostanza')
										setSubstancesEditMode(true);
										setEditSubstanceId(index);
									}}
								>
									Aggiungi sostanza
								</a>
							</div>
						</React.Fragment>
						) || (substance.text !== '') && ( // Casistica in cui la sostanza è stata inserita e compilata
							<React.Fragment key={`substance-${index}`}>
								<p className="text-black font-medium text-xl">Sostanza {index + 1}*</p>
								<div className="flex flex-col items-end sm:flex-row sm:items-center gap-6">
									<input
										placeholder="Nome sostanza*"
										value={substance.text}
										className="border text-black border-gray-500 rounded px-4 py-2 text-sm w-full"
										disabled
									/>
									<a
										className="border-0 bg-primary w-fit px-4 py-1 text-xs rounded-full flex flex-row items-center gap-2 cursor-pointer text-black"
										onClick={() => {
											// console.log('modifica sostanza')
											setSubstancesEditMode(true);
											setEditSubstanceId(index);
										}}
									>
										Modifica
										<svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z" fill="#000000" /></svg>
									</a>
								</div>
								{
									index === substancesInfo.substances.length - 1 && ( // Casistica in cui la sostanza è stata inserita e compilata ed è l'ultima
										<div className="flex flex-col gap-3 pb-4">
											<p className="text-black font-medium text-xl">Sostanza {index + 2}*</p>
											<a
												className="border-0 bg-primary w-fit px-4 py-1 text-xs rounded-full flex flex-row gap-2 cursor-pointer text-black"
												onClick={() => {
													// console.log('aggiungi sostanza')
													addSubstance(index + 1);
													setSubstancesEditMode(true);
													setEditSubstanceId(index + 1);
												}}
											>
												Aggiungi sostanza
											</a>
										</div>
									)
								}
							</React.Fragment>
						)
					)
				})
			}
		</section>
	);
};

