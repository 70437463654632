import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

export const CustomDropzone = () => {
  const [selectedFile, setSelectedFile] = useState('');

  const onDrop = useCallback((acceptedFiles: any) => {
    console.log("acceptedFiles", acceptedFiles)
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      const fileAsBinaryString = reader.result;
      console.log("fileAsBinaryString", fileAsBinaryString)
      // do whatever you want with the file content
    }
    // reader.onabort = () => console.log('file reading was aborted')
    // reader.onerror = () => console.log('file reading has failed')
    if (file) {
      reader.readAsBinaryString(file);
      // console.log(file.name)
      setSelectedFile(file.name)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop, accept: {
    'image/jpeg': [],
    'image/png': [],
    'application/pdf': [],
  } })
  // fai in modo che vengano accettati solo file con estensione .pdf


  return (

    <div {...getRootProps()} className='border-dotted border text-gray-600 border-gray-800 rounded px-4 py-2 text-sm w-full cursor-pointer'>
      <input {...getInputProps()}/>
      {
        selectedFile === '' && (
          <div className='flex flex-row justify-between'>
            <p>Carica il file</p>
            <svg width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g id="Complete">
                <g id="upload">
                  <g>
                    <path d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    <g>
                      <polyline data-name="Right" fill="none" id="Right-2" points="7.9 6.7 12 2.7 16.1 6.7" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      <line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="12" x2="12" y1="16.3" y2="4.8" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        )
      }
      {
        selectedFile !== '' && (
          <div className='flex flex-row justify-between'>
            <p color='black'>{selectedFile}</p>
            <svg fill="#000000" height="18px" width="18px" version="1.1" id="Capa_1"
              viewBox="0 0 27.965 27.965" onClick={
                (event) => {
                  setSelectedFile('');
                  event?.stopPropagation();
                }
              }>
              <g>
                <g id="c142_x">
                  <path d="M13.98,0C6.259,0,0,6.261,0,13.983c0,7.721,6.259,13.982,13.98,13.982c7.725,0,13.985-6.262,13.985-13.982
			            C27.965,6.261,21.705,0,13.98,0z M19.992,17.769l-2.227,2.224c0,0-3.523-3.78-3.786-3.78c-0.259,0-3.783,3.78-3.783,3.78
			            l-2.228-2.224c0,0,3.784-3.472,3.784-3.781c0-0.314-3.784-3.787-3.784-3.787l2.228-2.229c0,0,3.553,3.782,3.783,3.782
			            c0.232,0,3.786-3.782,3.786-3.782l2.227,2.229c0,0-3.785,3.523-3.785,3.787C16.207,14.239,19.992,17.769,19.992,17.769z"/>
                </g>
                <g id="Capa_1_104_">
                </g>
              </g>
            </svg>
          </div>
        )
      }
    </div>
  )
}

