import clsx from 'clsx';

interface ButtonProps {
	size?: 'sm' | 'md' | 'lg';
	children: React.ReactNode;
	className?: string;
	onClick?: () => void;
}

export const Button = ({
	children,
	size = 'md',
	className,
	onClick,
}: ButtonProps) => {
	return (
		<button
			onClick={onClick}
			className={clsx(`rounded `, className)}
		>
			{children}
		</button>
	);
};
